import { IAppTheme } from '@msx/platform-services';

export const getStyles = (theme: IAppTheme) => {
    return {
        imageContainerDiv: {
            textAlign: "center",
            paddingTop: '30px',
            paddingBottom: '30px',
        },
        imageContainer: {
            height: "224px",
            maxHeight: "400px",
            maxWidth: "100%",
            boxShadow: theme.name === 'default' ? '0px 18px 24px 3px #A7C9FC80' : '0px 18px 24px 3px #333333',
            '@media(min-height:550px) and (max-height:750px)':{
                height:'200px',
                maxHeight:'200px'

            }
        },
        subHeadingContainer: {
            //marginTop: 24
        },
        subheading: {
            marginBottom: "10px",
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px'
        },
        description: {
            marginBottom: 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
        bulletPoints: {
            marginBottom: 0,
            textAlign: "justify",
            position: "relative",
            right: "0.5em",
        },
        modalContainer: {
            ".ms-Dialog-title": {
                padding: 5
            },
            ".ms-Dialog-button--close": {
                bottom: '12px',
                left: '12px',
            },
            ".ms-Dialog-main": {
                borderRadius: '8px',
                overflowY: 'hidden',
            },
            ".ms-Dialog-inner": {
                padding: '0px'
            },
            ".ms-Dialog-actions": {
                paddingBottom: '24px',
                paddingRight: '24px',
                paddingLeft: '24px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100% !important',
                margin: '0',
                paddingTop: '12px',
                '@media (max-height: 550px)': {
                    position:'relative',
                }
            },
            '.ms-Dialog-actionsRight': {
                width: '100% !important',
                alignItems: 'center',
                display: 'flex',
            },
            ".ms-Dialog-action": {
                width: '100%'
            },
            ".ms-Modal-scrollableContent": {
                overflowY: 'hidden',
                '@media (max-height: 550px)': {
                    overflowY: 'auto',
                },
            }
        },
        announcementContentContainer: {
            overflow: "hidden",
        },
        useDialogStyles: {
            root: {
                marginBottom: '10px !important',
            },
        },
        bodyDescriptionStyles: {
            padding: '24px',
            height: '164px'
        },
        stepper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            right: '8px',
            '@media (forced-colors: active)': {
                filter: 'invert(1)'
            }
        },          
        step: {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            background: theme.palette.themeLight,
            margin: '0 4px',
        },
        activeStep: {
            width: '16px',
            height: '8px',
            borderRadius: '100px',
            background: theme.palette.themePrimary,
        },
        footerButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        dialogFooter: {
            width: '100%',
            height: '10vh',
            maxHeight: '60px',
            bottom: '0',
            backgroundColor: theme.palette.white,
        },
        knowMoreLink: {
            textDecoration: 'underline',
        },
        descriptionContainer: {
            overflowY: 'auto', 
            '@media (min-height:550px) and (max-height: 756px)': {
                overflowY: 'auto',
            },
            '@media (max-height: 550px)': {
                overflowY:'auto',
                height:'auto'
            }
        }
    }
};


